<template>
  <div>
    <builder-content
      :dataTest="test"
      :testId="idTest"
      :courseId="courseId"
      :type_form_test="type_form_test"
      :is_free="is_free"
      :offset="offset"
    ></builder-content>
  </div>
</template>

<script>
// import ApiService from '@/service/api.service';
export default {
  name: "AddTest",
  components: {
    BuilderContent: () => import("./Form/TestBuilderForm"),
  },
  props: ["courseId", "type_form_test", "is_free", "offset"],
  data() {
    return {
      is_call_api: false,
      test: {
        test_title: "",
        test_description: "",
        time_restriction: 0,
        duration: 0,
        cover: "",
        skills: [],
      },
      idTest: null,
    };
  },
  computed: {},
  created() {},
  methods: {},
};
</script>

<style></style>
